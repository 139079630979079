import HiddenNameIcon from "@assets/icons/general/Hidden";
import useIsWindowFocused from "@hooks/use-is-window-focused";
import useLanguage, { Dict } from "@hooks/useLanguage";
import { GameHistory, useNewGameHistory } from "@hooks/useSocket";
import RewardIcons, { RewardCode } from "@items/RewardIcons";
import ValueDisplay from "@items/ValueDisplay";
import { useGameHistoryQueue } from "@lib/Queue";
import isWin from "@lib/tools/is-win";
import { cn } from "@lib/utils";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { memo, useCallback, useMemo } from "react";
import RollingList from "./rolling-list";
export const WIN_FEED_LIMIT = 13;
export const winsFeedPing = <div className="w-5 h-5 flex items-center justify-center">
    <span className="relative flex h-2 w-2">
      <span className="absolute inline-flex h-full w-full rounded-full bg-green opacity-75 animate-wins-feed-ping" />
      <span className="relative inline-flex rounded-full h-2 w-2 bg-light-green" />
    </span>
  </div>;
export const WinsFeedTitle = ({
  className
}: {
  className?: string;
}) => <Dict section="ResultFeed" name="live_feed" className={cn("justify-start text-white text-xs font-medium", className)} as="h3" data-sentry-element="Dict" data-sentry-component="WinsFeedTitle" data-sentry-source-file="wins-feed.tsx" />;
const FallbackGameImage = ({
  name
}: {
  name: string;
}) => <div className="w-full relative aspect-3/4 rounded-md bg-dark-one opacity-80 group-hover:-translate-y-1" data-sentry-component="FallbackGameImage" data-sentry-source-file="wins-feed.tsx">
    <span className="absolute inset-0 flex items-center justify-center text-center leading-normal">
      {name}
    </span>
  </div>;
const [ratioWidth, ratioHeight] = [3, 4];
const resultWidth = 200;
const resultHeight = ratioHeight * resultWidth / ratioWidth;
type ResultProps = Readonly<GameHistory>;
const Result = memo(function Result({
  id,
  user,
  currency,
  currencyPayout,
  game
}: ResultProps) {
  const {
    username,
    rankLevel
  } = user;
  const L = useLanguage("common");
  const icon = game?.["custom_banner"] || game?.["customBanner"] || game?.icon;
  const {
    pathname
  } = useRouter();
  const href = useMemo(() => ({
    pathname,
    query: {
      modal: "bet",
      tab: "result",
      betId: id
    }
  }), [pathname, id]);
  return <Link shallow href={href} className="hover:translate-x-4 transition-transform duration-300">
      {icon ? <Image src={icon} alt="game" width={resultWidth} height={resultHeight} className="aspect-3/4 rounded-md object-cover object-center" /> : <FallbackGameImage name={game.name} />}

      <div className="flex flex-col mt-2.5 justify-center items-center">
        <div className="flex items-center gap-1">
          {rankLevel?.name && username ? <RewardIcons code={rankLevel?.name?.toUpperCase() as RewardCode} size="large" /> : null}

          <div className={cn("max-w-[85px] flex gap-1 justify-center")}>
            <p className={cn("text-xs text-input-label-color-light truncate", !username && "text-font-color-two")}>
              {username || L("hidden")}
            </p>

            {!username && <HiddenNameIcon className="translate-y-0.5" />}
          </div>
        </div>

        <ValueDisplay amount={currencyPayout} size="small" customClass="[&_span]:text-xs [&_span]:text-light-green/90" currencyCode={currency} />
      </div>
    </Link>;
});
type WinsFeedProps = Readonly<{
  initialData: GameHistory[];
}>;
export default function WinsFeed({
  initialData = []
}: WinsFeedProps) {
  const initial = useMemo(() => initialData, [initialData]);
  const isWindowFocused = useIsWindowFocused();
  const {
    queue,
    output = [],
    ref
  } = useGameHistoryQueue({
    initial,
    limit: WIN_FEED_LIMIT
  });
  const fn = useCallback((item: GameHistory) => {
    if (!isWindowFocused || !isWin(item)) return;
    queue.add(item);
  }, [queue, isWindowFocused]);
  useNewGameHistory(fn);
  if (!output?.length) return null;
  return <RollingList orientation="horizontal" gap={10} ref={ref} data-sentry-element="RollingList" data-sentry-component="WinsFeed" data-sentry-source-file="wins-feed.tsx">
      {output?.map?.((result, i) => <Result id={result.id} key={`${result.id}-${i}`} {...result} />)}
    </RollingList>;
}
export const WinsFeedHome = (props: WinsFeedProps) => {
  return <div className="min-w-[calc(1200px-32px)] relative" data-sentry-component="WinsFeedHome" data-sentry-source-file="wins-feed.tsx">
      <div className={cn("absolute left-0 top-0 z-10", "bg-[#333A5B] rounded-md", "inline-flex justify-center items-center gap-[5px]", "bg-[#333A5B] outline outline-[5px] outline-[#0f1328]", "pl-[5px] pr-2.5 py-[5px]")}>
        <div className="flex items-center gap-[5px] pl-[5px] pr-2.5 py-[5px] bg-[#323A5D] rounded-[3px]">
          {winsFeedPing}

          <WinsFeedTitle data-sentry-element="WinsFeedTitle" data-sentry-source-file="wins-feed.tsx" />
        </div>
      </div>

      <WinsFeed {...props} data-sentry-element="WinsFeed" data-sentry-source-file="wins-feed.tsx" />
    </div>;
};
export const WinsFeedCasino = (props: WinsFeedProps) => {
  return <div className="min-w-[calc(1200px-32px)] relative mb-6" data-sentry-component="WinsFeedCasino" data-sentry-source-file="wins-feed.tsx">
      <div className="flex items-center gap-2 mb-4">
        {winsFeedPing}
        <WinsFeedTitle className="text-md" data-sentry-element="WinsFeedTitle" data-sentry-source-file="wins-feed.tsx" />
      </div>

      <WinsFeed {...props} data-sentry-element="WinsFeed" data-sentry-source-file="wins-feed.tsx" />
    </div>;
};